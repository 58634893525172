<template>
  <div class="fixed-center text-center">
    <p>
      <img src="~assets/sad.svg" style="width: 30vw; max-width: 150px" />
    </p>
    <p class="text-faded">
      You don't have access to this page...<strong>(403)</strong>
    </p>
    <v-btn color="secondary" style="width: 250px; margin-top: 1rem" to="/login"
      >Go Back to login page</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "Error404",
};
</script>
